import './App.css';
import AllRoutes from './routes/AllRoutes';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  
  return (
    <div>
       <AllRoutes/>
    </div>
  );
}

export default App;
