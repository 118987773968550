import React from 'react';
import '../../styles/footer.css';
import instaIcon from "../../assets/images/insta.png"
import linkedinIcon from "../../assets/images/linkedinIcon.png"
import youtubeIcon from "../../assets/images/youtube.png"
import revfolksIcon from "../../assets/images/TheRevFolks1 1.png"
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            <footer className="footer">
                <div className="footer-top">
                    <div className="footer-logo">
                        <Link to="/" className="footer-brand">
                            <img src={revfolksIcon} alt="" className='small-screen-logo' />
                        </Link>
                    </div>
                    <div className="footer-social">
                        <Link style={{textDecoration:"none"}} to="https://www.instagram.com/therevfolks/" target='_blanck' >
                            <img src={instaIcon} alt="" className='social-icons' />
                        </Link>
                        <Link style={{textDecoration:"none"}} to="https://www.linkedin.com/company/therevfolks/about/" target='_blanck'>
                            <img src={linkedinIcon} alt="" className='social-icons' />
                        </Link>
                        <Link style={{textDecoration:"none"}} to="https://www.youtube.com/channel/UCZ-Wa62M_hDl0Ly5SeRwWmw" target='_blanck'>
                            <img src={youtubeIcon} alt="" className='social-icons' />
                        </Link>
                    </div>
                </div>
                <div className="footer-bottom text-center pt-4">
                    <div>Copyright © 2024. All Rights Reserved.</div>
                </div>
            </footer>
        </>
    )
}

export default Footer